import { useEffect, useMemo, useState, useCallback } from "react"
import { useConnection, useWallet } from "@solana/wallet-adapter-react"
import { toast } from 'react-toastify';

import Button from "../components/Button"
import NFTListing from "../components/NFTListing"

import { getNFTsForWalletAndFirstCreator } from '../helpers/NftHelper'
import StakingHelper from '../helpers/StakingHelper'

import StakingApi from '../network/Staking'
import { PublicKey } from "@solana/web3.js";

export default function StakingPage({
  creatorsWallets,
  collectionSize,
  collection,
  getColorForNfts,
  dripUrl,
  tiers = [],
  clubs = [],
}) {
  const { connection } = useConnection()
  const { publicKey, signAllTransactions } = useWallet()

  const [isLoading, setIsLoading] = useState(false)
  const [isProcessingMassTransactions, setIsProcessingMassTransactions] = useState(false)
  const [availableNFTs, setAvailableNFTs] = useState([])
  const [stakedNftsInApi, setStakedNftsInApi] = useState([])
  const [rewardsPerDay, setRewardsPerDay] = useState(0)
  const [availableToClaim, setAvailableToClaim] = useState(0)
  const [totalStaked, setTotalStaked] = useState(0)

  const nftsData = useMemo(() => {
    return availableNFTs.map(onChainNft => {
      const stakingProperties = stakedNftsInApi.find(apiNft => apiNft.mint_address === onChainNft.mint)
      return { ...onChainNft, stakingProperties, }
    })
  }, [availableNFTs, stakedNftsInApi])

  const stakedNfts = useMemo(() => {
    return nftsData.filter(item => !!item.stakingProperties)
  }, [nftsData])

  const unstakedNFTs = useMemo(() => {
    return nftsData.filter(item => !item.stakingProperties)
  }, [nftsData])

  const stakedPercentage = useMemo(() => {
    return (totalStaked * 100 / collectionSize).toFixed(2)
  }, [totalStaked])

  const loadTotalStakedNfts = async () => {
    try {
      const data = await StakingApi.totalStakedAmount()
      if (collection === 'puffsterz') {
        setTotalStaked(parseInt(data.by_collection?.puffsterz))
      } else if (collection === 'vibe_tribe') {
        setTotalStaked(
          parseInt(data.by_collection?.vibe_tribe_1 || '0') +
          parseInt(data.by_collection?.vibe_tribe_2 || '0') +
          parseInt(data.by_collection?.vibe_tribe_3 || '0') +
          parseInt(data.by_collection?.vibe_tribe_4 || '0')
        )
      }
    } catch (error) {
      console.log("Error in loadTotalStakedNfts", error)
      toast.error('Error getting the total staked nfts.')
    }
  }

  const loadWalletNfts = useCallback(async () => {
    if (publicKey) {
      const promises = creatorsWallets.map(async (creatorWallet) => await getNFTsForWalletAndFirstCreator(publicKey, creatorWallet))
      let nfts = await Promise.all(promises)
      nfts = nfts.flat()
      setAvailableNFTs(nfts)
    } else {
      setAvailableNFTs([])
    }
  }, [publicKey])

  const loadWalletData = useCallback(async () => {
    if (publicKey) {
      setIsLoading(true)

      try {
        const data = await StakingApi.staked({ walletAddress: publicKey.toBase58() })
        setStakedNftsInApi(data)
      } catch (error) {
        console.log("Error in loadWalletData", error)
        toast.error('Error getting your staked nfts.')
      }

      try {
        const data = await StakingApi.stakingData({ walletAddress: publicKey.toBase58() })
        setAvailableToClaim(parseFloat(data.available_to_claim))
        setRewardsPerDay(parseFloat(data.rewards_per_day))
      } catch (error) {
        console.log("Error in loadWalletData", error)
        toast.error('Error getting your staking analytics.')
      }

      setIsLoading(false)
    } else {
      setStakedNftsInApi([])
      setAvailableToClaim(0)
      setRewardsPerDay(0)
    }
  }, [publicKey])

  const stakingHelper = useMemo(() => {
    return new StakingHelper(async () => {
      await loadWalletData()
      await loadTotalStakedNfts()
    }, connection, signAllTransactions, publicKey, toast)
  }, [loadWalletData, signAllTransactions, connection, publicKey])

  useEffect(() => { loadTotalStakedNfts() }, [])
  useEffect(() => { loadWalletData() }, [loadWalletData])
  useEffect(() => { loadWalletNfts() }, [loadWalletNfts])

  const stakeNftByMint = async (mintAddress) => { return await stakingHelper.startStake([mintAddress]) }
  const unstakeNftByMint = async (mintAddress) => { return await stakingHelper.startUnstake([mintAddress]) }
  const stakeAllNFTs = async () => {
    setIsProcessingMassTransactions(true)
    await stakingHelper.startStake(unstakedNFTs.map(item => item.mint))
    setIsProcessingMassTransactions(false)
  }
  const unStakeAllNFTs = async () => {
    setIsProcessingMassTransactions(true)
    await stakingHelper.startUnstake(stakedNfts.map(item => item.mint))
    setIsProcessingMassTransactions(false)
  }
  const claimReward = async () => {
    setIsProcessingMassTransactions(true)
    await stakingHelper.startClaimRewards()
    setIsProcessingMassTransactions(false)
  }

  return (
    <div className="flex w-full gap-4 flex-col xl:flex-row">
      <div className="w-full xl:w-1/6 order-0">
        <div className="flex flex-col border-4 border-[#4d4d4d] rounded-3xl justify-center items-center p-4 gap-4 bg-gradient-to-b from-[#00000080] to-black">
          <h3 className="text-white text-center text-xl border-b border-white w-full">Tiers</h3>
          <div className="flex flex-col gap-2 w-full">
            {tiers.map(tier => (
              <p style={{ color: tier.color }}>{tier.text}</p>
            ))}
          </div>

          {clubs.length > 0 && (
            <>
              <h3 className="text-white text-center text-xl border-b border-white w-full">Clubs</h3>
              <div className="flex flex-col gap-2 w-full">
                {clubs.map(club => (
                  <p className="text-[#999999]">{club}</p>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="w-full xl:w-2/6 order-2 md:order-1">
        <NFTListing
          title='Unvaped NFTs'
          emtpyTitle="You don't have any Unvaped NFT."
          items={unstakedNFTs}
          stakeNft={stakeNftByMint}
          unstakeNft={unstakeNftByMint}
          isLoading={isLoading}
          isProcessingMassTransactions={isProcessingMassTransactions}
          getColorForNfts={getColorForNfts}
        />
      </div>

      <div className="w-full xl:w-1/6 order-1 md:order-2">
        <div className="flex flex-col border-4 border-[#4d4d4d] rounded-3xl justify-center items-center p-4 gap-4">
          <div className="flex flex-col w-full gap-1">
            <h3 className="text-[#a9ffcc] text-center">Vaped {stakedPercentage}%</h3>
            <div className="w-full bg-[#4d4d4d] rounded-full h-2.5 ring ring-[#4d4d4d]">
              <div className="bg-gradient-to-r from-black to-[#a9ffcc] h-2.5 rounded-full" style={{ width: `${stakedPercentage}%` }}></div>
            </div>
            <h5 className="text-[#a9ffcc] text-right text-xs">{totalStaked}/{collectionSize}</h5>
          </div>

          <h3 className="text-[#a9ffcc]">$VAPE/DAY - {rewardsPerDay}</h3>
          <Button
            title='Vape All'
            onClick={stakeAllNFTs}
            loading={isLoading || isProcessingMassTransactions}
            disabled={unstakedNFTs.length === 0}
          />
          <Button
            title={`Claim ${availableToClaim} $VAPE`}
            onClick={claimReward}
            loading={isLoading || isProcessingMassTransactions}
            disabled={availableToClaim === 0}
          />
          <Button
            title='Unvape all'
            onClick={unStakeAllNFTs}
            loading={isLoading || isProcessingMassTransactions}
            disabled={stakedNfts.length === 0}
          />
        </div>

        <a href={dripUrl} target="_blank" rel="noreferrer">
          <img src='/drip.png' alt='Drip Bonus' className="w-full" />
        </a>
      </div>

      <div className="w-full xl:w-2/6 order-3">
        <NFTListing
          title='Vaped NFTs'
          emtpyTitle="You don't have any Vaped NFT."
          items={stakedNfts}
          stakeNft={stakeNftByMint}
          unstakeNft={unstakeNftByMint}
          isLoading={isLoading}
          isProcessingMassTransactions={isProcessingMassTransactions}
          getColorForNfts={getColorForNfts}
        />
      </div>
    </div>
  )
}